<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3335 8H2.66683M2.66683 8L6.66683 4M2.66683 8L6.66683 12"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  </i>
</template>

<style scoped lang="scss">
i {
  width: 24px;
  height: 24px;
  display: inline-flex;
}
</style>
